// import axiosServices from '@/services/axiosServices.js'
import store from '@/store/index.js'
import axios from 'axios';

// estas son las funciones que se realizaran cuando lleguen los mensajes del WebSocketbundleRenderer.renderToStream
export default {
  onGetUrlBase () {
    return store.state.sUrlBase
  },
  onCleanUrlBase(){
    store.dispatch('saveUrlBase', "");
  },
  async onConfigurarUrlBase (sSubdominio) {
    const sURLDefault = 'https://slim.seiftmp.safetytrack.co/';
    const sURLSeif = 'https://slim.seiftmp.safetytrack.co/';
    return new Promise(async (resolve, reject) => {
      store.dispatch('setCargandoUrlBase', true);
      try {
        if(sSubdominio != "desarrollo"){
          const data = {
            tag:"Configuracion",
            sSubdominio: sSubdominio,
          }
          const response = await axios.post(`${sURLSeif}login-app/conexion/url?subdominio=centrocontrol`, data);
          const bErrorConexion = response.data.aData.bError
          if(bErrorConexion){
            store.dispatch('notificaciones/SetMensaje', response.data.aData.sMensaje)
            store.dispatch('notificaciones/ShowNotificacion',true)
          }
          store.dispatch('saveUrlBase', ( (response.data.sStatus =="success" && !bErrorConexion) ? response.data.aData.sUrlApi : sURLDefault) );
          store.dispatch('setsTipoApp', response.data.aData.sTipoApp);
        }
        store.dispatch('setCargandoUrlBase', false);
        resolve(true);
      } catch (error) {
        if (store.state.sUrlBase=="") {
          store.dispatch('saveUrlBase', sURLDefault)
        }
        store.dispatch('setCargandoUrlBase', false)
        reject(false);
      }
    });
  },
  onValidarUrlBase (bForzado = false) {
    if (!store.state.login || store.state.sUrlBase=="" || bForzado) {
      //Si el usuario esta deslogueado o si la URL no esta seteada o si es forzado, siempre se consulta la url base para actualizarla
      this.onConfigurarUrlBase(store.state.sSubDominio)       
    }
  }
  
}
import APPutils from '@/utils/APPutils.js'

export default {
  getCurrentPosition () {

    // Si la PWA esta embebida en Android, solicitamos a la App las coordenadas directamente
    if(typeof IApp !== "undefined" && (typeof IApp.onGetLocalizacionActual === 'function')){
      return APPutils.onGetLocalizacionActual()
    }

    var oOpciones = {
      enableHighAccuracy: true,
      maximumAge: 10000, // deshabilita el uso de la coordenada por cache
      timeout: 5000 // Setea el maximo tiempod e peticion de las coordenadas
    };
    // se regresa un objeto con la propiedad coords y dentro estan la propiedades  latitude y longitude
    // se llama  this.oLocation = await GPSLocation.getCurrentPosition()
     
    if (navigator.geolocation) {
      return new Promise(
        (resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, oOpciones)
      )
    } else {

      return new Promise(
        resolve => resolve({})
      )
    }
  }
  
  
}
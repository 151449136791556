<template>
  <div class="margen-form">
    <v-container>

      <!--Seccion titulo-->
      <v-col class="pa-0 mb-2" cols="12">    
        <HeaderVista :sTitulo="sTitulo" sColor="black--text" :sSubTitulo="sSubTitulo">
          <template v-slot:icono>
            <v-icon large color="red"> mdi-map-marker </v-icon>
          </template>
        </HeaderVista>
      </v-col>

      <!--Seccion Preguntas Punto-->
      <v-col class="pa-0 mb-2" cols="12" v-for="pregunta,index in preguntas">
        <PreguntaItemCard
          v-model="aRespuestasPregunta[index]"
          :oPregunta="pregunta">
        </PreguntaItemCard>
        </v-col>
      <v-sheet
        class="text-center contenedor-bottom mt-5"
        >
          <v-col class="pa-0">
            <v-row no-gutters justify="center" align="center">
              <v-btn block @click="onEnviar" large dark color="primary" class="btn-primary btn-rounded">
                {{ $STR.btnEnviar }}
              </v-btn>
            </v-row> 
            <v-row no-gutters  justify="center" align="center" class="mt-2">
              <v-btn block @click="onCancelar" large dark color="secondary" outlined class="btn-primary btn-rounded">
                {{ $STR.btnCancelar }}
              </v-btn>
            </v-row>  
          </v-col>
      </v-sheet>
    </v-container>
    <DialogMensaje
      v-model="bDialogCamposObligatorios"
      :titulo="sTituloMensaje"
      :texto="sMensajeError"
      :bOcultarCancelar="true"
      @onAceptar="fAceptar"
    >
      <template v-slot:icono>
        <v-icon large :color="sIconoColor"> {{ sIcono }}</v-icon>
      </template>
    </DialogMensaje>
  </div>
  
</template>
<script>
import RondasRepository  from '@/views/control-actividades/rondas/RondasRepository.js'
import HeaderVista from '@/components/HeaderVista.vue';
import PreguntaItemCard from '@/components/PreguntaPuntoItemCard.vue';
import DialogMensaje from '@/components/DialogMensaje.vue'
import APPutils from '@/utils/APPutils.js'
import HoraFecha from "@/utils/Hora_Fecha.js"
import GPSLocation from '@/utils/GPSlocation.js'
import { mapGetters } from 'vuex'

export default {
  name: 'PuntosPorRonda',
  components: {
    HeaderVista,
    PreguntaItemCard,
    DialogMensaje
  },
  data() {
    return {
      sIdRondaCalendario : '0',
      nIdPunto : 0,
      sTitulo: '',
      sSubTitulo: '',
      nPuntosRealizados: 0,
      nPuntosTotales: 0,
      idSedeBase : 0,
      sCodigo: "",
      oRonda:null,
      preguntas: [],
      aRespuestasPregunta: [],
      aFotos:[],
      oLocation : {},
      bErrorPermisos : false ,
      bDialogCamposObligatorios:false,
      sTituloMensaje:"",
      sMensajeError:"",
      sFormulario: "Ronda Marcacion Preguntas",
      fAceptar:()=>{},
      sIcono: "",
      sIconoColor: "",
    };
  },
  async mounted() {
    this.sIdRondaCalendario = this.$route.params.idRonda;
    this.sCodigo = this.$route.params.codigo;
    this.onGetPreguntasPuntoRonda()
    try{
      this.oLocation = await GPSLocation.getCurrentPosition()
      this.bErrorPermisos = false
    }catch(e){
      if(e.message=='User denied Geolocation'){
        this.bErrorPermisos = true
      }
      // Si hay error capturo el objeto segun la Doc https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
      APPutils.onGuardarLog("Error GeolocationPositionError: {code:"+e.code+", message:"+e.message+"}")
    } 
  },
  methods: {
    onGetPreguntasPuntoRonda(){
      this.oRonda = RondasRepository.onGetRondaById(this.sIdRondaCalendario)
      let oPunto = RondasRepository.onGetPuntoByCodigo(this.sIdRondaCalendario, this.sCodigo)
      if(oPunto !=undefined){
        this.preguntas = oPunto.aPreguntas
        this.sTitulo = oPunto.sNombrePunto
        this.sSubTitulo = `Asociado a la Ronda: ${oPunto.sNombreRonda}`
        this.nIdPunto = oPunto.sIdPunto
      }

      if(this.getsTipoApp == "1"){
        if(this.getnIDSedeBase > 0){
          this.idSedeBase = this.getnIDSedeBase
        }
      }
      
    },
    onCancelar(){
      this.$router.back()
    },
    async onEnviar(){
      if(!this.isFormularioValido()){
        const sParams = `app=PWA`
        //Generamos array con el nombre de las fotos tomadas para subirlas
        const aRespuestasFotosPregunta = [];
        this.aRespuestasPregunta.forEach( async oResPregunta => {
          if(Object.keys(oResPregunta.fotoAnexo).length > 0){
            aRespuestasFotosPregunta.push(oResPregunta.fotoAnexo)
          }
        })

        //Formateo las respuesta con anexos para solo subir el nombre de la foto en la respuesta
        const aRespuestasFormateadas = this.aRespuestasPregunta.map( oRespuesta => {
          if(Object.keys(oRespuesta.fotoAnexo).length > 0){
            oRespuesta.fotoAnexo = oRespuesta.fotoAnexo.NombreFoto
          }else{
            oRespuesta.fotoAnexo = 'sinfoto.jpg'
          }
          return oRespuesta
        })

        const oBody = {
          imei : this.getimei,
          app : "ReporteMarcacionListaPreguntas",
          idPunto: this.nIdPunto,
          sIdActividadCalendario: this.sIdRondaCalendario,
          sIdActividad: this.oRonda.sIdActividad,
          idSede: this.idSedeBase,
          aRespuestasPregunta: aRespuestasFormateadas,
          aFotos : [], //Cada foto se envia en el array de respuesta de cada pregunta
          tipoRespuesta: "marcacion",
          Latitud : this.oLocation.coords.latitude,
          Longitud : this.oLocation.coords.longitude,
          fecha : HoraFecha.getNow(),
        }
        const oResponse = await RondasRepository.onReporteMarcacion(sParams, oBody, aRespuestasFotosPregunta, this.getisAPP)
        if(!Object.prototype.hasOwnProperty.call(oResponse, 'nStatusCode')) {
          if(this.getisAPP) {
            this.onActualizarEstadoRonda(oBody)
            APPutils.enviarDataOffline(oBody, this.sFormulario)
            //APPutils.onSubirFotosApp(this.sFormulario)
          }
        }else {
          if ((oResponse.nStatusCode == 200) || (oResponse.nStatusCode == 220)  ){
            if(this.getisAPP) {
              //Como ya el registro subio, no existe pendiente(id=0) por ende solo resta subir fotos
              APPutils.onSubirFotosApp(this.sFormulario, 0)
            }
            switch (oResponse.nStatusCode) {
              case 200: {
                this.onActualizarEstadoRonda(oBody)
                this.$swal({
                  timer: 1500,
                  icon: `${oResponse.sStatus}`,
                  title: `${oResponse.sMessage}`,
                  showConfirmButton: false
                })
                break
              }
              case 220: {
                this.onNotificarRondaEnProgreso(this.sIdRondaCalendario, this.getisAPP)
                break
              }
            }
            //APPutils.registro_OK()
          } else {
            if(this.getisAPP) {
              APPutils.enviarDataOffline(oBody, this.sFormulario)
              //APPutils.onSubirFotosApp(this.sFormulario)
            }
          }
        }
      }else{
        this.sTituloMensaje="Campos requeridos"
        this.bDialogCamposObligatorios = true
        this.sIcono = 'mdi-alert'
        this.sIconoColor = 'red'
      }
    },
    isFormularioValido(){
      this.sMensajeError = ''
      let error = false

      if(this.tipoReporte=="0"){
        this.sMensajeError += '- Tipo de Reporte no diligenciado.'
        error = true
      }

      let nRespuestasIncompletas = 0;
      this.aRespuestasPregunta.forEach( async oResPregunta => {
        if(oResPregunta.valorRespuesta == 0){
          if(oResPregunta.sObservacion == '' || Object.keys(oResPregunta.fotoAnexo).length == 0){
            nRespuestasIncompletas++
          }
        }
      })
      
      if(nRespuestasIncompletas>0){
        error = true
        this.sMensajeError += `- Faltan ${nRespuestasIncompletas} Observaciones o Fotos de Novedad por llenar.`
      }

      return error
    },
    onActualizarEstadoRonda(oBody){
      if(RondasRepository.onActualizarProgresoRonda(this.sIdRondaCalendario, this.nIdPunto, oBody, this.getisAPP)){
        this.volverAlInicio()
      }else{
        this.salir()
      }
    },
    salir () {
      this.$router.replace(`/PuntosPorRonda/${this.sIdRondaCalendario}`);
    },
    onNotificarRondaEnProgreso (sIdActividadCalendario, bIsApp=false) {
      this.sTituloMensaje="Ronda en progreso"
      this.sMensajeError = this.$STR.msgRondaBloqueadaIniciadaDesdeOtroUsuario
      this.bDialogCamposObligatorios = true
      this.sIcono = 'mdi mdi-check-circle-outline'
      this.sIconoColor = 'green'
      RondasRepository.onActualizarEstadoRonda(sIdActividadCalendario, "Bloqueado", bIsApp)
      this.fAceptar = () => {
        this.volverAlInicio()
      }
    },
    volverAlInicio() {
      window.history.replaceState(null, '', '/DashboardRondas');
      window.history.pushState(null, '', '/DashboardRondas');
      this.$router.replace('/DashboardRondas')
    },
  },
  computed: {
    ...mapGetters(['getbMenuBlock', 'getnIDSedeBase', 'getsTipoApp', 'getisAPP']),
  }
}
</script>
<style lang="scss" scoped>
.contenedor-bottom {
  background-color: unset;
  bottom: 0;
  width: 100%;
  margin-bottom: 50px;
}
</style>  